import React from 'react'

const About = () => {
  return (
    <div name='about' className='w-full h-screen bg-gradient-to-b from-black via-gray-900 to-gray-700 text-gray-300'>
    
        <div className='flex flex-col justify-center items-center w-full h-full'>
          
        <div className='max-w-[1000px] w-full grid grid-cols-2 gap-8'>
            <div className='sm:text-right pb-8 pl-4'>
                <p className='text-4xl font-bold inline border-b-4 border-pink-600'>About</p>

            </div>
            </div>
            <div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4'>
                <div className='sm:text-right text-4xl font-bold'>
                  <p>
                  Hi. I'm Atharva, nice to meet you. Please take a look around

                  </p>
                </div>
                <div className='text-1xl'>
                  <p>
                 I am passionate about building excellent software that improves the lives of those around me. I enjoy working in teams to accomplish a bigger goal.

                  </p>
                </div>
            </div>

            </div>

        </div>



    
  )
}

export default About