import React from 'react'
import Python from "../asset/python.png";
import Java from "../asset/java-logo-1.png";
import react1 from "../asset/React.png";
import tableau from "../asset/tableau.png";
import androidstudio from "../asset/androidstudio.png";
import MySQL from "../asset/MySQL.png";


const Skills = () => {
  return (
    <div name='skills' className='w-full h-screen bg-gradient-to-b from-gray-700 via-gray-900 to-black text-gray-300'>
        {/*Container*/}
        <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
            <div>
                <p className='text-4xl font-bold inline border-b-4 border-pink-600 '>Experience</p>
                <p className='py-4'> These are the software tools I have worked with</p>
            </div>

            <div className=' w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8'>

                <div className=' shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={Python} alt= "Python Icon"/>
                    <p className=' my-4 font-bold'>PYTHON</p>
                </div>
                <div className=' shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={Java} alt= "Java Icon"/>
                    <p className=' my-4 font-bold'>JAVA</p>
                </div>
                <div className=' shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={react1} alt= "react Icon"/>
                    <p className=' my-4 font-bold'>REACT</p>
                </div>
                <div className=' shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={tableau} alt= "tableau Icon"/>
                    <p className=' my-4 font-bold'>Tableau</p>
                </div>
                <div className=' shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={androidstudio} alt= "androidstudio Icon"/>
                    <p className=' my-4 font-bold'>Android Studio</p>
                </div>
                <div className=' shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto' src={MySQL} alt= "mysql Icon"/>
                    <p className=' my-4 font-bold'>MySQL</p>
                </div>
            </div>

        </div>


    </div>
  )
}

export default Skills