import React from 'react'
import {HiArrowNarrowRight} from 'react-icons/hi'
import Typed from 'react-typed';
import {Link} from 'react-scroll'
const Home = () => {
  return (
    <div name='home' className='w-full h-screen bg-gradient-to-b from-gray-700 via-gray-900 to-black'>
    { /*Container*/}


      <div className='max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full'>

      <p className=' text-2xl text-pink-600 py-2'> Hi, My name is</p>
      <h1 className='text-4xl sm:text-7xl font-bold text-[#ccd6f6] hover:text-pink-600 hover:border-pink-600'lassName>Atharva Temkar</h1>
      <h2 className='text-4xl sm:text-7xl font-bold text-[#8892b0]'>I am a junior developer</h2>
      <Typed className='text-1xl sm:text-5xl font-bold text-[#8892b0] flex py-4' strings ={['Hello', 'नमस्ते','Hola','नमस्कार']} typeSpeed={120} backSpeed={140} loop/>
      <p className='text-[#8892b0] py-4 max-w-[700px]'> DEscription</p>

      <div>

      <button className='text-white group border-2 px-6 py-3 my-2 flex items-center hover:bg-pink-600 hover:border-pink-600'>View Work
      <span className='group-hover:rotate-90 duration-300'>
      <HiArrowNarrowRight className='ml-3'/> 
      </span>
      <Link activeClass="active" to="work" smooth={true} duration={500}></Link>
      </button>
        
      </div>

      </div>
      </div>

  )
}


export default Home