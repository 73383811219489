import React, {useState} from 'react';
import Logo from '../asset/logo.png';
import {FaBars, FaTimes, FaGithub, FaLinkedin} from 'react-icons/fa';
import {HiOutlineMail} from 'react-icons/hi'
import {BsFillPersonLinesFill} from 'react-icons/bs'
import {Link} from 'react-scroll'

import resume from '../asset/ATemkarResumepdf.pdf';

const NavBar = () => {
    const[nav ,setNav] = useState(false)
    const handleClick = () => setNav(!nav)
  return (
    <div className='fixed w-full h-[80px] flex justify-between items-center px-4 bbg-gradient-to-b from-gray-700 via-gray-900 to-black text-gray-300'>
        <div>
        <img src={Logo} alt ="Logo Image" style={{width: '70px'}}/>

        </div>

        {/* menu   */}
        
            <ul className='hidden md:flex'>
                <li><Link activeClass="active" to="home" smooth={true} duration={500}>Home</Link></li>
                <li><Link activeClass="active" to="about" smooth={true} duration={500}>About</Link> </li>
                <li> <Link activeClass="active" to="skills" smooth={true} duration={500}>Skills</Link> </li>
                <li> <Link activeClass="active" to="work" smooth={true} duration={500}>Work</Link> </li>
                <li> <Link activeClass="active" to="Contact" smooth={true} duration={500}>Contact</Link> </li>
            </ul>
     

        {/*Hamburger*/}

    <div onClick={handleClick}className='md:hidden z-10'>

        {!nav ? <FaBars /> : <FaTimes /> }

    </div>

    {/*Mobile Menu*/}
    <ul className={!nav ? 'hidden': 'absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center'}>
                <li className='py-6 text-4xl'> <Link onClick={handleClick} activeClass="active" to="home" smooth={true} duration={500}>Home</Link></li>
                <li className='py-6 text-4xl'> <Link onClick={handleClick} activeClass="active" to="about" smooth={true} duration={500}>About</Link></li>
                <li className='py-6 text-4xl'> <Link onClick={handleClick} activeClass="active" to="skills" smooth={true} duration={500}>Skills</Link></li>
                <li className='py-6 text-4xl'> <Link onClick={handleClick} activeClass="active" to="work" smooth={true} duration={500}>Work</Link> </li>
                <li className='py-6 text-4xl'> <Link onClick={handleClick} activeClass="active" to="Contact" smooth={true} duration={500}>Contact</Link></li>
    </ul>



    {/*Social Icons*/}
    <div className='hidden lg:flex fixed flex-col top-[35%] left-0'>

        <ul>
            <li className='w=[160px] h-[60px] flex justify-between items-center ml-[-70px] hover:ml-[-10px] duration-300 bg-blue-500'>
                <a className='flex justify-between items-center w-full text-gray-300' href="https://www.linkedin.com/in/atharva-temkar-53b56a197?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app">
                    LinkedIn <FaLinkedin size={30} />

                </a>
            </li>
            
            <li className='w=[160px] h-[60px] flex justify-between items-center ml-[-70px] hover:ml-[-10px] duration-300 bg-[#333333]'>
                <a className='flex justify-between items-center w-full text-gray-300' href="https://github.com/Athsonfire1?tab=projects">
                    GitHub <FaGithub size={30} />

                </a>
            </li>
            <li  className='w=[160px] h-[60px] flex justify-between items-center ml-[-70px] hover:ml-[-10px] duration-300 bg-[#6FC2B0]'>
                <a className='flex justify-between items-center w-full text-gray-300' href="atharvatemkar@gmail.com">
                    Email <HiOutlineMail size={30} />

                </a>
            </li>
            <li className='w=[160px] h-[60px] flex justify-between items-center ml-[-70px] hover:ml-[-10px] duration-300 bg-[#565F69]'>
                <a className='flex justify-between items-center w-full text-gray-300' href={resume} download={resume}>
                    Resume <BsFillPersonLinesFill size={30} />

                </a>
            </li>

        </ul>
    </div>
    </div>
  )
}

export default NavBar
